
import Vue from "vue";
import { Mentorcast } from "@/types/interfaces";

export default Vue.extend({
  name: "DiscoverMentorcastItem",
  props: {
    mentorcast: {
      type: Object as () => Mentorcast
    }
  }
});
